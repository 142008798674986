export const types = [
  ['Standard', 'std',],
  ['Trap', 'trap',],
  ['Yuri', 'yuri',],
  ['Anal', 'anal',],
  ['Futa', 'futa',],
]

export const yesno = [
  ['Yes', true],
  ['No', false],
]

export const popular = [
  ['Popular', true],
  ['Random', false],
]
