const counter = parseInt(localStorage.getItem('visitCounter')) || 0

export const hero_1 = [
  {
    text: counter > 0 ? 'Welcome back.' : 'Hello stranger.',
  },
  {
    text: 'First you need to say what kind of images do you want to watch?',
  },
  {
    text: '',
    flag: 'choices.type',
  },
  {
    text: 'Random or more Popular?',
  },
  {
    text: '',
    flag: 'choices.popular',
  },
  {
    text: 'So let it be. Just wait a second. I need to chose images for your session...',
    flag: 'slider.load',
  },
  {
    text: 'I found images for you.',
  },
  {
    text: 'Last question, do you want to use metronome?',
  },
  {
    text: '',
    flag: 'character.metronome',
  },
  {
    text: 'Have fun.',
  },
  {
    text: '',
    flag: 'metronome.start',
  },
]

export const hero_2 = [
  {
    text: 'Sadly, that was last image. Do you want continue? What kind of images do you want to watch?',
  },
  {
    text: '',
    flag: 'choices.type',
  },
  {
    text: 'Random or more Popular?',
  },
  {
    text: '',
    flag: 'choices.popular',
  },
  {
    text: 'So let it be. Just wait a second. I need to chose images for your session...',
    flag: 'slider.load',
  },
  {
    text: 'I\'m done.',
  },
  {
    text: 'Last question, do you want to use metronome?',
  },
  {
    text: '',
    flag: 'character.metronome',
  },
  {
    text: 'Have fun.',
  },
  {
    text: '',
    flag: 'metronome.start'
  },
]
