import { Howl } from 'howler'

export const SOUNDS = {
  WOODEN: 'wooden',
  HEARTHBEAT: 'heartbeat',
  DRUM: 'drum',
  DRYKICK: 'drykick',
  DOUMDECK: 'doumdek',
}

export const metronome = {}
metronome[SOUNDS.WOODEN] = new Howl({
  html5: !0,
  volume: 0.4,
  src: [
    '/audio/ping.mp3',
    '/audio/ping.ogg',
    '/audio/ping.wav'
  ],
})
metronome[SOUNDS.HEARTHBEAT] = new Howl({
  html5: !0,
  volume: 0.4,
  src: [
    '/audio/Heartbeat.mp3',
    '/audio/Heartbeat.ogg',
    '/audio/Heartbeat.wav'
  ],
})
metronome[SOUNDS.DRUM] = new Howl({
  html5: !0,
  volume: 0.4,
  src: [
    '/audio/Hand-Drum.mp3',
    '/audio/Hand-Drum.ogg',
    '/audio/Hand-Drum.wav'
  ],
})
metronome[SOUNDS.DRYKICK] = new Howl({
  html5: !0,
  volume: 0.4,
  src: [
    '/audio/Dry-Kick.mp3',
    '/audio/Dry-Kick.ogg',
    '/audio/Dry-Kick.wav'
  ],
})
metronome[SOUNDS.DOUMDECK] = new Howl({
  html5: !0,
  volume: 0.4,
  src: [
    '/audio/Doumbek-Doum.mp3',
    '/audio/Doumbek-Doum.ogg',
    '/audio/Doumbek-Doum.wav'
  ],
})