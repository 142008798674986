<template>
    <router-view/>
</template>

<style lang="scss">
    body {
        background-color: #111;;
    }

    a {
        color: #7fffd4;
        text-decoration: none;
        transition: color .1s;

        &:hover, &:focus {
            color: #55a989;
        }
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #f6f6f6;
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
