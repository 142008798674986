export const STATUS = {
  HAPPY: 1,
  SMILE: 2,
  EMBARRASSED: 3,
  WORRIED: 4,
  SAD: 5,
  SURPRISED: 6,
  SMUG: 7,
  CONFOUNDED: 8,
}

export const VERSION = {
  NORMAL: 1,
  BLUSH: 2,
}

const IMAGES = {}
IMAGES[VERSION.NORMAL] = {}
IMAGES[VERSION.NORMAL][STATUS.HAPPY] = 'STA01A_L_5_13.png'
IMAGES[VERSION.NORMAL][STATUS.SMILE] = 'STA01A_L_5_14.png'
IMAGES[VERSION.NORMAL][STATUS.EMBARRASSED] = 'STA01A_L_5_15.png'
IMAGES[VERSION.NORMAL][STATUS.WORRIED] = 'STA01A_L_5_16.png'
IMAGES[VERSION.NORMAL][STATUS.SAD] = 'STA01A_L_5_17.png'
IMAGES[VERSION.NORMAL][STATUS.SURPRISED] = 'STA01A_L_5_18.png'
IMAGES[VERSION.NORMAL][STATUS.SMUG] = 'STA01A_L_5_19.png'
IMAGES[VERSION.NORMAL][STATUS.CONFOUNDED] = 'STA01A_L_5_20.png'
IMAGES[VERSION.BLUSH] = {}
IMAGES[VERSION.BLUSH][STATUS.HAPPY] = 'STA01A_L_5_10_13.png'
IMAGES[VERSION.BLUSH][STATUS.SMILE] = 'STA01A_L_5_10_14.png'
IMAGES[VERSION.BLUSH][STATUS.EMBARRASSED] = 'STA01A_L_5_10_15.png'
IMAGES[VERSION.BLUSH][STATUS.WORRIED] = 'STA01A_L_5_10_16.png'
IMAGES[VERSION.BLUSH][STATUS.SAD] = 'STA01A_L_5_10_17.png'
IMAGES[VERSION.BLUSH][STATUS.SURPRISED] = 'STA01A_L_5_10_18.png'
IMAGES[VERSION.BLUSH][STATUS.SMUG] = 'STA01A_L_5_10_19.png'
IMAGES[VERSION.BLUSH][STATUS.CONFOUNDED] = 'STA01A_L_5_10_20.png'

export const character = {
  namespaced: true,
  state: () => ({
    version: VERSION.NORMAL,
    display: false,
    textLock: false,
    choices: [],
    context: null,
    status: STATUS.HAPPY,
    texts: [],
    lineIndex: 0,
  }),
  mutations: {
    setTexts (state, texts) {
      state.lineIndex = 0
      state.texts = texts
    },
    lock (state) {
      state.textLock = true
    },
    showChoices (state, {context, choices}) {
      state.choices = choices
      state.context = context
      state.textLock = true
    },
    hideChoices (state) {
      state.context = null
      state.choices = []
    },
    unlock (state) {
      state.textLock = false
    },
    hide (state) {
      state.display = false
    },
    show (state) {
      state.display = true
    },
    setStatus (state, {status, version}) {
      state.status = status || STATUS.HAPPY
      state.version = version || VERSION.NORMAL
    },
    nextLine (state) {
      state.lineIndex++
    }
  },
  actions: {
    choice ({commit, dispatch, state}, choice) {
      dispatch('choice', {choice, context: state.context}, {root: true})
      commit('hideChoices')
      commit('unlock')
      dispatch('next')
    },
    changeStatus ({commit}, status) {
      commit('setStatus', {status})
    },
    next ({dispatch, commit, state}) {
      if (state.textLock) {
        return
      }
      commit('nextLine')
      if (!state.texts[state.lineIndex]) {
        commit('hide')
      } else if (state.texts[state.lineIndex].flag) {
        dispatch('flag', state.texts[state.lineIndex].flag, {root: true})
      }
    },
  },
  getters: {
    text (state) {
      return state.texts[state.lineIndex] ? state.texts[state.lineIndex].text : false
    },
    source (state) {
      return IMAGES[state.version][state.status] || IMAGES[VERSION.NORMAL][STATUS.HAPPY]///require()
    },
  },
}