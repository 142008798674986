import { createStore } from 'vuex'
import { popular } from '../character/choices'
import { types, yesno } from '../character/choices.js'
import { hero_2 } from '../character/text'
import { sleep } from '../helpers'
import { character } from './character'
import { metronome } from './metronome'
import { slider } from './slider'

export default createStore({
  state: {
    withMetronome: false,
  },
  mutations: {
    withMetronome (state) {
      state.withMetronome = true
    },
    withoutMetronome (state) {
      state.withMetronome = false
    },
  },
  actions: {
    changeImage ({commit, dispatch, getters}) {
      if (getters['slider/tillEnd'] === 1) {
        commit('metronome/setMax')
      } else if (getters['slider/tillEnd'] === 0) {
        sleep(8000).then(() => {
          commit('metronome/hide')
          commit('metronome/resetSpeed')
        })
      } else {
        dispatch('metronome/changeSpeed')
      }
    },
    async finishImage ({commit}) {
      commit('slider/close')
      commit('character/setTexts', hero_2)
      commit('character/show')
    },
    choice ({commit,}, {context, choice}) {
      switch (context) {
        case 'slider/popular':
          commit('slider/setPopular', choice)
          break
        case 'slider/type':
          commit('slider/setType', choice)
          break
        case 'metronome/display':
          commit(choice ? 'withMetronome' : 'withoutMetronome')
          break
      }
    },
    async flag ({commit, dispatch, state}, msg) {
      if (msg === 'slider.load') {
        commit('character/lock')
        const counter = parseInt(localStorage.getItem('visitCounter')) || 0
        localStorage.setItem('visitCounter', counter + 1)
        await dispatch('slider/loadImages', 'std')
        commit('character/unlock')
        dispatch('character/next')
      }
      if (msg === 'choices.type') {
        commit('character/showChoices', {context: 'slider/type', choices: types})
      }
      if (msg === 'choices.popular') {
        commit('character/showChoices', {context: 'slider/popular', choices: popular})
      }
      if (msg === 'metronome.start') {
        commit('character/hide')
        if (state.withMetronome) {
          commit('metronome/show')
          dispatch('metronome/start')
        }
      }
      if (msg === 'character.metronome') {
        commit('character/showChoices', {context: 'metronome/display', choices: yesno})
      }
    }
  },
  modules: {
    character,
    metronome,
    slider,
  }
})
