import { SOUNDS } from '../api/metronome'
import { inRange, random, sleep } from '../helpers'

export const MODE_MANUAL = 'manual'
export const MODE_AUTO_S = 'auto s'
export const MODE_AUTO_F = 'auto f'

const FAST_S = [120, 180]
const MEDI_S = [70, 110]
const SLOW_S = [40, 60]

const FAST_F = [180, 240]
const MEDI_F = [120, 170]
const SLOW_F = [40, 110]

const SOUNDS_POOL = [
  SOUNDS.WOODEN,
  SOUNDS.HEARTHBEAT,
  SOUNDS.DRUM,
  SOUNDS.DRYKICK,
  SOUNDS.DOUMDECK,
]

export const metronome = {
  namespaced: true,
  state: () => ({
    display: false,
    speed: 60,
    running: false,
    mode: MODE_MANUAL,
    soundId: 0,
    changeCounter: 0,
    circles: [],
  }),
  mutations: {
    hide (state) {
      state.display = false
      state.running = false
    },
    show (state) {
      state.display = true
    },
    speedUp (state) {
      state.speed = Math.min(state.speed + 10, 240)
    },
    speedDown (state) {
      state.speed = Math.max(state.speed - 10, 40)
    },
    addCircle (state) {
      state.circles.push({delay: 1000 + (60 / state.speed * 1000), key: Math.random()})
    },
    start (state) {
      state.running = true
    },
    stop (state) {
      state.running = false
    },
    shift (state) {
      state.circles.shift()
    },
    switchMode (state) {
      switch (state.mode) {
        case MODE_AUTO_S:
          state.mode = MODE_AUTO_F
          break
        case MODE_AUTO_F:
          state.mode = MODE_MANUAL
          break
        case MODE_MANUAL:
        default:
          state.mode = MODE_AUTO_S
      }
    },
    switchSound (state) {
      if (state.soundId + 1 >= SOUNDS_POOL.length) {
        state.soundId = 0
      } else {
        state.soundId++
      }
    },
    resetSpeed (state) {
      state.speed = 60
    },
    setMax (state) {
      if (state.mode === MODE_AUTO_F) {
        state.speed = Math.max(...FAST_F)
      } else if (state.mode === MODE_AUTO_S) {
        state.speed = Math.max(...FAST_S)
      } else {
        state.speed = 240
      }
    },
    setFast (state) {
      if (state.mode === MODE_AUTO_F) {
        state.speed = random(FAST_F[0] / 10, FAST_F[1] / 10) * 10
      } else if (state.mode === MODE_AUTO_S) {
        state.speed = random(FAST_S[0] / 10, FAST_S[1] / 10) * 10
      }
    },
    setMedium (state) {
      if (state.mode === MODE_AUTO_F) {
        state.speed = random(MEDI_F[0] / 10, MEDI_F[1] / 10) * 10
      } else if (state.mode === MODE_AUTO_S) {
        state.speed = random(MEDI_S[0] / 10, MEDI_S[1] / 10) * 10
      }
    },
    setSlow (state) {
      if (state.mode === MODE_AUTO_F) {
        state.speed = random(SLOW_F[0] / 10, SLOW_F[1] / 10) * 10
      } else if (state.mode === MODE_AUTO_S) {
        state.speed = random(SLOW_S[0] / 10, SLOW_S[1] / 10) * 10
      }
    },
    changeModeCounterUp (state) {
      state.changeCounter++
    },
  },
  actions: {
    async loop ({state, commit,}) {
      while (state.running) {
        commit('addCircle')
        await sleep(60 / state.speed * 1000)
      }
    },
    changeSpeed ({state, commit, getters}) {
      if (!state.running || state.mode === MODE_MANUAL) {
        return
      }
      commit('changeModeCounterUp')
      if (state.mode === MODE_AUTO_F && state.changeCounter % 2 === 0) {
        if (getters.isFast) {
          random(1, 2) === 1 ? commit('setMedium') : commit('setSlow')
        } else if (getters.isMedium) {
          random(1, 2) === 1 ? commit('setSlow') : commit('setFast')
        } else {
          random(1, 2) === 1 ? commit('setMedium') : commit('setFast')
        }
      } else if (state.mode === MODE_AUTO_S && state.changeCounter % 4 === 0) {
        if (getters.isFast) {
          commit('setSlow')
        } else if (getters.isMedium) {
          commit('setFast')
        } else {
          commit('setMedium')
        }
      }
    },
    speedUp ({commit}) {
      commit('speedUp')
    },
    speedDown ({commit,}) {
      commit('speedDown')
    },
    start ({state, commit, dispatch,}) {
      if (!state.running) {
        commit('start')
        dispatch('loop')
      }
    },
  },
  getters: {
    sound (state) {
      return SOUNDS_POOL[state.soundId] || SOUNDS_POOL[0]
    },
    isFast (state) {
      switch (state.mode) {
        case MODE_AUTO_S:
          return inRange(state.speed, FAST_S[0], FAST_S[1])
        case MODE_AUTO_F:
          return inRange(state.speed, FAST_F[0], FAST_F[1])
        case MODE_MANUAL:
        default:
          return false
      }
    },
    isMedium (state) {
      switch (state.mode) {
        case MODE_AUTO_S:
          return inRange(state.speed, MEDI_S[0], MEDI_S[1])
        case MODE_AUTO_F:
          return inRange(state.speed, MEDI_F[0], MEDI_F[1])
        case MODE_MANUAL:
        default:
          return false
      }
    },
    isSlow (state) {
      switch (state.mode) {
        case MODE_AUTO_S:
          return inRange(state.speed, SLOW_S[0], SLOW_S[1])
        case MODE_AUTO_F:
          return inRange(state.speed, SLOW_F[0], SLOW_F[1])
        case MODE_MANUAL:
        default:
          return false
      }
    },
  },
}
