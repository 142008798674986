import { fetchPosts } from '../api/images'
import { preloadImage } from '../helpers'

export const slider = {
  namespaced: true,
  state: () => ({
    display: false,
    images: [],
    index: 0,
    popular: true,
    type: 'std',
  }),
  mutations: {
    setPopular (state, popular) {
      state.popular = popular
    },
    setType (state, type) {
      state.type = type
    },
    close (state) {
      state.display = false
      state.index = 0
      state.images = []
    },
    hide (state) {
      state.display = false
    },
    show (state) {
      state.display = true
    },
    setImages (state, images) {
      state.index = 0
      state.images = images
    },
    next (state) {
      state.index++
    },
  },
  actions: {
    async loadImages ({commit, state, getters}) {
      commit('setImages', await fetchPosts(state.type, state.popular))
      await preloadImage(getters.currentImage)
    },
    async changeImage ({commit, dispatch, getters}) {
      if (getters.nextImage !== null) {
        try {
          await preloadImage(getters.nextImage)
          commit('next')
        } catch (e) {
          commit('next')
        }
        if (getters.nextImage) {
          preloadImage(getters.nextImage).then().catch()
        }
        dispatch('changeImage', null, {root: true})
      } else {
        dispatch('finishImage', null, {root: true})
      }
    },
  },
  getters: {
    direction (state) {
      return state.index % 2 === 0 ? 'up' : 'down'
    },
    currentImage (state) {
      return state.images[state.index] || null
    },
    nextImage (state) {
      return state.images[state.index + 1] || null
    },
    tillEnd (state) {
      return Math.max(0, state.images.length - state.index - 1)
    },
  },
}