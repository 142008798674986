export function inRange (number, start, end) {
  return number >= Math.min(start, end) && number < Math.max(start, end)
}

export function random (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function sleep (delay) {
  return new Promise(resolve => {
    setTimeout(resolve, delay)
  })
}

export function preloadImage (src) {
  return new Promise((resolve, reject) => {
    let fulfilled = false
    const fail = (e) => {
      if (!fulfilled) {
        fulfilled = true
        reject(e)
      }
    }
    const image = new Image()
    image.onload = () => {
      fulfilled = true
      resolve(image)
    }
    image.onerror = fail
    image.onabort = fail
    setTimeout(() => fail('Timeout'), 5000)
    image.src = src
  })
}