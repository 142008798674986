import { sleep } from '../helpers'

export const gelbooru = async () => {
  // let page = 0
  // let lastResponse = true
  const map = post => {
    const ext = post.getAttribute('file_url').split('.').pop()
    const src = post.getAttribute('sample_url')
    const full = post.getAttribute('file_url')

    return {
      ext, src, full
    }
  }

  let url = 'https://api.codetabs.com/v1/proxy/?quest=https://gelbooru.com/?page=dapi&s=post&q=index&json=0&limit=50&tags=-rating%3Asafe%20score%3A>20%20-animated%20-focus_male%20anal'
  const response = await fetch(url).then(async response => {
    response = response.ok ? await response.text() : '<posts></posts>'
    return [...(new DOMParser).parseFromString(response.toString(), 'text/xml').querySelectorAll('post')]
  })

  return response
  // .filter(post => post.image.includes(['.jpeg', '.jpg', '.png']))
  // .filter(post => ['image/png', 'image/jpeg'].includes(post.file_type)) //only pngs/jpegs
    .map(map) //filter that images that have sample url
    .filter(post => ['jpeg', 'jpg', 'png'].includes(post.ext)) //filter empty
    .map(post => post.src)
    .filter(url => url)
}

const sankaku = async (type, popular) => {
  let baseUrl = ''
  const order = popular ? 'quality' : 'random'
  switch (type) {
    case 'trap':
      baseUrl = `https://capi-v2.sankakucomplex.com/posts/keyset?lang=en&default_threshold=1&hide_posts_in_books=in-larger-tags&limit=100&tags=order:${order}+-animated+-video+trap+-rating:safe`
      break
    case 'futa':
      baseUrl = `https://capi-v2.sankakucomplex.com/posts/keyset?lang=en&default_threshold=1&hide_posts_in_books=in-larger-tags&limit=100&tags=order:${order}+-animated+-video+futanari`
      break
    case 'anal':
      baseUrl = `https://capi-v2.sankakucomplex.com/posts/keyset?lang=en&default_threshold=1&hide_posts_in_books=in-larger-tags&limit=100&tags=order:${order}+-animated+-video+anal+-yaoi`
      break
    case 'yuri':
      baseUrl = `https://capi-v2.sankakucomplex.com/posts/keyset?lang=en&default_threshold=1&hide_posts_in_books=in-larger-tags&limit=100&tags=order:${order}+-animated+-video+yuri+-rating:safe`
      break
    case 'std':
    default:
      baseUrl = `https://capi-v2.sankakucomplex.com/posts/keyset?lang=en&default_threshold=1&hide_posts_in_books=in-larger-tags&limit=100&tags=order:${order}+female+-animated+-video+-rating:safe`
  }

  let url = baseUrl
  let images = []
  while (url && images.length < 250) {
    try {
      const response = await fetch(url).then(r => r.ok ? r.json() : [])

      images = [
        ...images,
        ...response.data
          .filter(post => ['image/png', 'image/jpeg'].includes(post.file_type)) //only pngs/jpegs
          .filter(post => post.sample_width / post.sample_height < 1.3)
          .map(post => post.sample_url)
          .filter(url => url)
      ]
      if (response.meta.next) {
        url = baseUrl + '&next=' + response.meta.next
      } else {
        url = false
      }
    } catch (e) {
      await sleep(500)
      console.error(e)
    }
  }
  images.sort(() => Math.random() - 0.5)
  return images.slice(0, 50)// images
}

export const fetchPosts = async (type, popular = true) => {
  return sankaku(type, popular)
}